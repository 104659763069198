import { Film, Image as IconImage, Package } from 'lucide-react'
import Image from 'next/image'
import { event } from 'nextjs-google-analytics'
import * as React from 'react'

import ShineBorder, { TColorProp } from '@/components/magicui/shine-border'
import { BorderBeam } from '@/components/ui/border-beam'
import { Button } from '@/components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'

export interface FakeCardProps {
    isAdCard: true
    title?: string
    image: string[]
    backDropImage?: string
    preferredIndex?: number
    firstPageOnly?: boolean
    size?: string
    link: string
    campaign: string
    text?: string
    rate: number
    videos?: number
    images?: number
    callToAction?: string
    callToActionStyle?: string
    borderAnimation?: 'shine' | 'snake'
    borderColors?: TColorProp
    ofBorder?: boolean
    // react component with props
    Icon: React.ReactElement
    DescriptionComponent?: React.ReactElement
}

// React.ComponentProps<typeof Card> and Content
type CardProps = React.ComponentProps<typeof Card>

const FakeCard = ({ className, contentData, ...props }: CardProps & { contentData?: FakeCardProps }) => {
    const [randomImage, setRandomImage] = React.useState<string | null>(null)

    React.useEffect(() => {
        if (contentData && contentData.image.length > 0) {
            const randomIndex = Math.floor(Math.random() * contentData.image.length)
            setRandomImage(contentData.image[randomIndex])
        }
    }, [contentData])

    if (!contentData) return <Skeleton className={cn('w-full aspect-[2/3.3] rounded-xl', className)} />

    const {
        title,
        Icon,
        backDropImage,
        callToActionStyle,
        callToAction,
        size,
        videos,
        link,
        text,
        borderAnimation,
        campaign,
        DescriptionComponent,
        images,
        ofBorder,
        borderColors,
    } = contentData

    const openLink = () => {
        event(`ad-card-${campaign}`, {
            category: 'content',
            label: campaign,
        })
        window.open(link, '_blank')
    }

    const InnerCard = (
        <Card
            data-vaul-no-drag
            className={cn(
                'relative group flex flex-col overflow-hidden z-10 bg-transparent',
                className,
                ofBorder ? 'border-2 border-onlyfans border-dashed' : ''
            )}
            {...props}
        >
            {borderAnimation && borderAnimation === 'snake' && <BorderBeam />}
            <div className='absolute inset-0  z-[-1] bg-card'>
                <Image
                    src={backDropImage || randomImage || ''}
                    alt={campaign}
                    width='300'
                    height={300}
                    className='w-full h-full object-cover scale-110 blur-lg opacity-40 transition-all group-hover:opacity-30'
                />
            </div>
            <div className='absolute top-1 right-1 flex flex-row gap-2'></div>
            <CardHeader className='flex flex-grow bg-card p-3  rounded-md m-2 mt-3 shadow-sm'>
                {title && <CardTitle className='flex  overflow-hidden pb-2'>{title}</CardTitle>}
                <CardDescription className=''>
                    {DescriptionComponent && DescriptionComponent}
                    <div className='flex flex-row items-center text-sm text-secondary-foreground flex-wrap'>
                        {size && (
                            <div className=' flex items-center mr-2'>
                                <Package className='h-4 w-4 mr-1' />
                                {size}
                            </div>
                        )}
                        {images && (
                            <div className=' flex items-center mr-2'>
                                <IconImage className='h-4 w-4 mr-1' />
                                {images}
                            </div>
                        )}
                        {videos && (
                            <div className='flex items-center mr-2'>
                                <Film className='h-4 w-4 mr-1' />
                                {videos}
                            </div>
                        )}
                    </div>
                    {text && (
                        <div className='flex flex-row items-center text-sm mt-1 flex-wrap'>
                            <div className='flex items-center flex-row mr-2 justify-center'>{text}</div>
                        </div>
                    )}
                </CardDescription>
            </CardHeader>
            <CardContent className='m-3 pl-0 pr-0 relative overflow-hidden transition-all group-hover:shadow-card'>
                <Image
                    width={500}
                    height={500}
                    src={randomImage || ''}
                    alt={campaign}
                    onClick={openLink}
                    className='w-full aspect-square object-contain transition-all group-hover:scale-110 rounded-md'
                />
            </CardContent>

            <CardFooter className='flex items-center ml-2 mr-2 p-2 pb-3 space-x-2'>
                <Button
                    onClick={() => {
                        openLink()
                    }}
                    variant='secondary'
                    className={cn(
                        'cursor-pointer get-content flex-grow',
                        callToAction === 'Get Premium'
                            ? 'bg-red-500'
                            : callToAction === 'Free OnlyFans'
                              ? 'bg-[#4eacea]'
                              : cn(callToActionStyle) // <--- Wrap callToActionStyle in cn()
                    )}
                >
                    {React.cloneElement(Icon, {
                        className: cn('h-4 w-4 mr-2', Icon.props.className),
                    })}
                    {callToAction || 'Free OnlyFans'}
                </Button>
            </CardFooter>
        </Card>
    )

    if (borderAnimation && borderAnimation === 'shine')
        return (
            <ShineBorder
                className=''
                borderWidth={4}
                borderRadius={10}
                color={borderColors || ['#A07CFE', '#FE8FB5', '#FFBE7B']}
            >
                {InnerCard}
            </ShineBorder>
        )
    else return InnerCard
}

export default FakeCard
