import { useQuery } from '@tanstack/react-query'
import { Annoyed, XIcon } from 'lucide-react'
import * as React from 'react'

import { fetchFull, fetchSimilar } from '@/api/api'
import ContentCard, { CardType } from '@/components/Content/ContentCard'
import { Drawer, DrawerContent } from '@/components/ui/drawer'
import useQueryParams from '@/provider/query-provider'

export function ContentDrawer() {
    const { setParam, query } = useQueryParams()
    // rename dat to content
    const { data: content, isSuccess: contentSuccess } = useQuery({
        queryKey: ['content', query.leak],
        queryFn: () => fetchFull(query.leak as string),
        enabled: typeof query.leak === 'string',
    })

    const { data: similarContent, isSuccess: similarContentSuccess } = useQuery({
        queryKey: ['content', query.leak, 'similar'],
        queryFn: () => fetchSimilar(query.leak as string),
        enabled: typeof query.leak === 'string',
    })

    const close = () => {
        setParam('leak', undefined)
    }

    return (
        <Drawer
            open={typeof query.leak === 'string'}
            onClose={close}
            dismissible
            shouldScaleBackground={false}
        >
            <DrawerContent className='w-full'>
                <button onClick={close} className='absolute right-2 top-2'>
                    <XIcon className='h-5 w-5 ' />
                </button>
                <div data-vaul-no-drag className=' m max-m'>
                    {/*<DrawerHeader>*/}
                    {/*    <DrawerTitle>{data?.title}</DrawerTitle>*/}
                    {/*    <DrawerDescription>Set your daily activity goal.</DrawerDescription>*/}
                    {/*</DrawerHeader>*/}
                    <div data-vaul-no-drag className='mx-auto  m-2 flex overflow-x-auto '>
                        <div className='flex m-3 '>
                            <ContentCard
                                className='h-[450px] sm:h-[520px] aspect-[2/3.3] flex-shrink-0'
                                contentData={content}
                                cardType={CardType.CONTENT_DRAWER}
                            />
                        </div>
                        {similarContentSuccess ? (
                            <div className='flex-grow p-6 flex flex-col'>
                                <h2 className='text-muted-foreground flex-grow'>
                                    You might also like...
                                </h2>
                                <div className='flex space-x-4 end py-4  '>
                                    {similarContent?.length > 0 ? (
                                        similarContent.map((item) => (
                                            <ContentCard
                                                key={item.id}
                                                className='flex-shrink-0 h-[400px] sm:h-[470px] aspect-[2/3.3] '
                                                contentData={item}
                                                cardType={CardType.CONTENT_GRID}
                                            />
                                        ))
                                    ) : (
                                        <div className='flex-shrink-0 h-[400px] sm:h-[470px] aspect-[2/3.3]  flex flex-col items-center justify-center'>
                                            <div className='text-lg font-bold text-primary-foreground'>
                                                Nothing here...
                                            </div>
                                            <Annoyed className='h-24 w-24 text-primary opacity-40 m-4' />
                                            <div className='text-sm text-muted-foreground text-center'>
                                                No similar content found, <br /> we are sorry.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='flex-grow p-6 flex flex-col'>
                                <h2 className='text-muted-foreground flex-grow'>
                                    You might also like...
                                </h2>
                                <div className='flex space-x-4  py-4 '>
                                    <ContentCard
                                        contentData={undefined}
                                        cardType={CardType.CONTENT_GRID}
                                        className='flex-shrink-0 h-[400px] sm:h-[470px] aspect-[2/3.3] '
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}

export default ContentDrawer
