import { useInfiniteQuery } from '@tanstack/react-query'
import { Annoyed, Loader2, RefreshCcw } from 'lucide-react'
import { event } from 'nextjs-google-analytics'
import React, { useEffect, useRef } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

import { fetchContent } from '@/api/api'
import { AdBlockerDetector } from '@/components/ads/AdBlockerDetector'
import { ButtonWithAds } from '@/components/ads/ButtonWithAds'
import ContentDrawer from '@/components/Content/content-drawer'
import ContentGrid from '@/components/Content/ContentGrid'
import ContentGridLoading from '@/components/Content/ContentGridLoading'
import SearchBox from '@/components/Content/SearchBox'
import { Button } from '@/components/ui/button'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useAdPlacement } from '@/provider/ad-placement-provider'
import useParams from '@/provider/query-provider'
import { Content, ContentSorting, Ethnicity } from '@/types/content.type'

const Home = () => {
    const parentRef = useRef<HTMLDivElement | null>(null)
    const { query, setParam } = useParams()
    const { insertOnPage } = useAdPlacement()

    const sorting = (query.s as ContentSorting) || ContentSorting.NEWEST
    const ethnicity = (query.e as Ethnicity) || Ethnicity.ALL
    const search = sorting !== ContentSorting.RANDOM ? (query.q as string | '') : ''

    useEffect(() => {
        if (sorting === ContentSorting.RANDOM) {
            setParam('q', undefined)
        }
    }, [sorting])

    const {
        data,
        refetch,
        isError,
        fetchNextPage,
        isFetchingNextPage,
        isFetchingPreviousPage,
        hasNextPage,
        isLoading,
        isRefetching,
    } = useInfiniteQuery({
        // eslint-disable-next-line
        queryKey: ['content', search, sorting, ethnicity],
        queryFn: async ({ pageParam }) => {
            const resData = await fetchContent({
                search: search,
                cursor: pageParam as string,
                ethnicity,
                sorting: sorting,
            })

            let index: number | undefined = undefined

            data?.pages.forEach((page, ix) => {
                if (page.cursorId == resData.cursorId) index = ix
            })
            if (index == undefined) index = data?.pages.length || 0

            resData.content = insertOnPage(index, resData.content) as Content[]

            return resData
        },
        initialPageParam: '',
        retryDelay: 10000,
        gcTime: 1000 * 60,
        getNextPageParam: (lastPage) => {
            if (typeof lastPage !== 'undefined' && lastPage.cursorId) return lastPage.cursorId
            return undefined
        },
        refetchOnWindowFocus: false,
    })

    const fetchMore = () => {
        if (
            !isLoading &&
            hasNextPage &&
            !isFetchingNextPage &&
            !isError &&
            !isFetchingPreviousPage &&
            !isLoading &&
            !isRefetching
        ) {
            fetchNextPage()
        }
    }

    const refetchData = async () => {
        if (!isLoading && !isFetchingNextPage && !isRefetching && !isFetchingPreviousPage) {
            refetch()
        }
    }

    return (
        <div ref={parentRef} className='flex-1 h-full overflow-auto '>
            <ContentDrawer />
            <AdBlockerDetector />
            <div className='flex items-center justify-center text-center'>
                <div className='container flex flex-col  justify-between space-y-2 gap-2 sm:gap-0 py-4 sm:flex-row  items-center sm:space-y-0 md:h-15'>
                    <SearchBox
                        initialSearch={search}
                        onSearch={(search) => {
                            event('search', {
                                category: 'content',
                                label: search,
                            })
                            setParam('q', search)
                        }}
                        sorting={sorting}
                        onChangeSorting={(sorting) => {
                            event('sort', {
                                category: 'content',
                                label: sorting,
                            })
                            setParam('s', sorting)
                        }}
                    />
                    <div className='flex items-center md:p-2 space-x-4 flex-row h-10 text-sm'>
                        {sorting !== ContentSorting.HOT && (
                            <Select
                                value={ethnicity}
                                onValueChange={(value) => {
                                    event('filter', {
                                        category: 'content',
                                        label: value,
                                    })
                                    setParam('e', value)
                                }}
                            >
                                <SelectTrigger className='w-35 overflow-hidden'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value={Ethnicity.ALL}>
                                            <div className='inline-block font-sans'>All Ethnicities</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.ARAB}>
                                            <div className='inline-block font-sans'>Arab</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.ASIAN}>
                                            <div className='inline-block font-sans'>Asian</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.BLACK}>
                                            <div className='inline-block font-sans'>Ebony</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.INDIAN}>
                                            <div className='inline-block font-sans'>Indian</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.LATINO}>
                                            <div className='inline-block font-sans'>Latina</div>
                                        </SelectItem>

                                        <SelectItem value={Ethnicity.WHITE}>
                                            <div className='inline-block font-sans'>White</div>
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}

                        <ButtonWithAds
                            variant='secondary'
                            size='icon'
                            onClick={refetchData}
                            disabled={
                                isLoading || isFetchingNextPage || isRefetching || isFetchingPreviousPage
                            }
                        >
                            {isLoading ||
                            isFetchingNextPage ||
                            isRefetching ||
                            isFetchingPreviousPage ? (
                                <RefreshCcw className='w-4 h-4 animate-spin' />
                            ) : (
                                <RefreshCcw className='w-4 h-4' />
                            )}
                        </ButtonWithAds>
                    </div>
                </div>
            </div>

            {!data && !isError && <ContentGridLoading />}

            {data && !isError && (
                <ContentGrid
                    fetchMore={fetchMore}
                    parentRef={parentRef}
                    content={data.pages.flatMap((page) => page.content)}
                />
            )}

            {isError && (
                <div className='flex flex-col items-center justify-center text-center'>
                    <p className='mt-4  text-sm text-muted-foreground'>
                        An error occurred while fetching the content, try reloading the page or disable
                        your VPN.
                    </p>
                </div>
            )}

            {hasNextPage ? (
                <VisibilitySensor
                    onChange={(visible: boolean) => {
                        if (visible) {
                            fetchMore()
                        }
                    }}
                >
                    <div className='mx-auto flex max-w-[420px] flex-col items-center justify-center text-center pt-10 p-4'>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                fetchMore()
                            }}
                            className='flex items-center justify-center gap-2'
                            disabled={!hasNextPage || isLoading || isFetchingNextPage || isRefetching}
                        >
                            {(isLoading || isFetchingNextPage || isRefetching) && (
                                <Loader2 className='w-4 h-4 animate-spin' />
                            )}
                            <p>
                                {isLoading || isFetchingNextPage || isRefetching
                                    ? 'Loading...'
                                    : 'Load More'}
                            </p>
                        </Button>
                    </div>
                </VisibilitySensor>
            ) : (
                <div className='mx-auto  max-w-[420px]  flex flex-col items-center justify-center text-center pt-10 p-4'>
                    <Annoyed className='h-20 w-20 text-account-bg opacity-40 mb-4' />
                    <h2 className='text-lg font-bold text-primary-foreground'>
                        You have reached the end!
                    </h2>

                    {data && data.pages.flatMap((page) => page.content).length == 0 ? (
                        <div className='mt-4  text-sm text-muted-foreground'>
                            We are sorry, but we couldn{"'"}t find what you are looking for.
                        </div>
                    ) : (
                        <div className='mt-4  text-sm text-muted-foreground'>
                            You have seen all the content we have to offer.
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Home
